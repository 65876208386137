import React from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "Common/useChartColors";

const OrdersOverviewChart = ({ chartId }: any) => {

    const chartColors = ["#6c757d"]; // Escala de grises (Gris oscuro)

    // Datos agrupados de lunes a sábado
    const series = [{
        name: 'Routes',
        data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6] // Ejemplo de datos para lunes a sábado
    }];

    var options: any = {
        chart: {
            height: 238,
            type: 'bar',
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 5,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val: any) {
                return val + "%";
            },
            style: {
                fontSize: '12px',
                colors: ["#495057"] // Gris medio para las etiquetas
            }
        },
        grid: {
            padding: {
                bottom: -10,
            }
        },
        xaxis: {
            categories: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"], // Agrupado por días de la semana
            position: 'top',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            tooltip: {
                enabled: true,
            }
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                formatter: function (val: any) {
                    return val + "%";
                }
            }
        },
        colors: chartColors, // Aplicando escala de grises
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                data-chart-colors='["#6c757d"]' // Gris oscuro
                id={chartId}
                className="apex-charts"
                type='bar'
                height={238}
            />
        </React.Fragment>
    );
};


export { OrdersOverviewChart };
