// Images
import avatar1 from "assets/images/pod/pod1.jpg";
import avatar2 from "assets/images/pod/pod2.jpg";
import avatar3 from "assets/images/pod/pod3.jpg";
import avatar4 from "assets/images/pod/pod4.jpg";
import avatar5 from "assets/images/pod/pod5.jpg";
import avatar6 from "assets/images/pod/pod6.jpg";

const UserListViewData = [
    { id: 1, userId: "#TW1500001", name: "Maria García", img: avatar2, designation: "Graphic Designer", location: "United Kingdom", email: "prohaska@tailwick.com", phoneNumber: "853 565 9802", joiningDate: "04 Jan, 2023", status: "Verified" },
    { id: 2, userId: "#TW1500002", name: "Jaqueline Hammes", img: avatar3, designation: "ASP.Net Developer", location: "Brazil", email: "jaqueline@tailwick.com", phoneNumber: "546 6334 586", joiningDate: "18 Jan, 2023", status: "Waiting" },
    { id: 3, userId: "#TW1500003", name: "Marlene Hirthe", img: avatar4, designation: "Angular Developer", location: "Spain", email: "marlene@tailwick.com", phoneNumber: "141 654 9876", joiningDate: "04 Feb, 2023", status: "Verified" },
    { id: 4, userId: "#TW1500004", name: "Akeem Paucek", img: avatar5, designation: "Jr. Developer", location: "Mexico", email: "akeem.p@tailwick.com", phoneNumber: "783 962 3972", joiningDate: "12 Feb, 2023", status: "Rejected" },
    { id: 5, userId: "#TW1500005", name: "Stephon Trantow", img: avatar6, designation: "Full Stack Developer", location: "Spain", email: "akeem.p@tailwick.com", phoneNumber: "032 126 5833", joiningDate: "31 Feb, 2023", status: "Waiting" },
    { id: 6, userId: "#TW1500006", name: "Domenic Tromp", designation: "Team Leader", location: "Germany", email: "domenic@tailwick.com", phoneNumber: "612 6088 735", joiningDate: "27 Oct, 2023", status: "Verified" },

];

const GridViewData = [
    { 
        id: 1, 
        img: avatar1, 
        name: "Pedro Pérez | 1723181290886355", 
        username: "Route MX1", 
        address: "Calle de la Reforma 123, Colonia Juárez, Ciudad de México, MX", 
        isActive: true,
        serviceProvider: "SP: DHL Express",
        route: "Ruta 12",
        zone: "Zona Centro",
        deliveryTime: "10:45 AM",
        recipient: "Carlos Hernández",
        geolocation: "19.432608, -99.133209" // Coordenadas de la ubicación de la entrega
    },
    { 
        id: 2, 
        img: avatar2, 
        name: "Maria García | 1723181290886855", 
        username: "Route MX1", 
        address: "Avenida Insurgentes Sur 4567, Tlalpan, Ciudad de México, MX", 
        isActive: true,
        serviceProvider: "SP: FedEx",
        route: "Ruta 8",
        zone: "Zona Sur",
        deliveryTime: "11:30 AM",
        recipient: "María García",
        geolocation: "19.326519, -99.187514" 
    },
    { 
        id: 3, 
        img: avatar3, 
        name: "Jaqueline suarez | 1723181290874155", 
        username: "Route MX2", 
        address: "Paseo de la Reforma 334, Colonia Cuauhtémoc, Ciudad de México, MX", 
        isActive: true,
        serviceProvider: "SP: UPS",
        route: "Ruta 5",
        zone: "Zona Norte",
        deliveryTime: "01:15 PM",
        recipient: "Luis Martínez",
        geolocation: "19.433035, -99.140922" 
    },
    { 
        id: 5, 
        img: avatar5, 
        name: "Miguel Hernández | 1723181290149355", 
        username: "Route MX1", 
        address: "Calle de la Amargura 789, Coyoacán, Ciudad de México, MX", 
        isActive: false,
        serviceProvider: "SP: Estafeta",
        route: "Ruta 3",
        zone: "Zona Sur",
        deliveryTime: "02:00 PM",
        recipient: "Sofía Fernández",
        geolocation: "19.355029, -99.162037" 
    },
     { id: 7, img: avatar5, name: "Daniel Miller", username: "@Subscriber", address: "431 Elk Rd Little Ticonderoga, NY", isActive: false },
    { id: 8, img: avatar6, name: "Ashley Wilson", username: "@Subscriber", address: "0816 Bradford Alley Lake Adamfort, ME", isActive: true },
    { id: 5, img: avatar4, name: "Aurore Maggio", username: "@Subscriber", address: "8751 Boyer Courts Suite 532 West Fletcherside", isActive: false },
    { id: 7, img: avatar5, name: "Daniel Miller", username: "@Subscriber", address: "431 Elk Rd Little Ticonderoga, NY", isActive: false },
    { id: 8, img: avatar6, name: "Ashley Wilson", username: "@Subscriber", address: "0816 Bradford Alley Lake Adamfort, ME", isActive: true },
    { id: 5, img: avatar4, name: "Aurore Maggio", username: "@Subscriber", address: "8751 Boyer Courts Suite 532 West Fletcherside", isActive: false },
    { id: 7, img: avatar5, name: "Daniel Miller", username: "@Subscriber", address: "431 Elk Rd Little Ticonderoga, NY", isActive: false },
    { id: 8, img: avatar6, name: "Ashley Wilson", username: "@Subscriber", address: "0816 Bradford Alley Lake Adamfort, ME", isActive: true },
  
];

export { UserListViewData, GridViewData };