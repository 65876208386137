import React from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "Common/useChartColors";

//import images
import logoSm from "assets/images/cnmx-sm.png";

const PerspectiveChart = ({ chartId }: any) => {
  const chartColors = [
    "#6c757d", // Gris oscuro claro
    "#5a6268", // Gris oscuro
    "#495057", // Gris más oscuro
    "#343a40", // Gris muy oscuro
    "#212529", // Gris casi negro
    "#1c1e21", // Gris más cercano al negro
    "#121314", // Gris muy cercano al negro
  ];

  const series = [
    {
      data: [
        {
          x: "Created Deliveries (400)",
          y: 400,
        },
        {
          x: "In Transit (200)",
          y: 200,
        },
        {
          x: "At Hub for Sorting (120)",
          y: 120,
        },
        {
          x: "Assigned to Courier (34)",
          y: 34,
        },
        {
          x: "Delivered (230)",
          y: 330,
        },
        {
          x: "Delayed Deliveries (30)",
          y: 30,
        },
        {
          x: "Customer Issues (44)",
          y: 44,
        },
      ],
    },
  ];

  const options: any = {
    legend: {
      show: false,
    },
    chart: {
      height: 270,
      type: "treemap",
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        top: -15,
        bottom: 0,
        right: -20,
      },
    },
    colors: chartColors,
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        data-chart-colors='["bg-custom-500"]'
        id="platformPerspective"
        className="apex-charts"
        type="treemap"
        height={270}
      />
    </React.Fragment>
  );
};

const LocationChart = ({ chartId }: any) => {
  const chartColors = [
    "#909090", // Gris medio oscuro
  ];

  // Serie de datos ajustada para representar la eficiencia operativa predicha a lo largo de los días de la semana
  const series = [
    {
      name: "Predicted Efficiency",
      data: [72, 75, 78, 80, 85, 90, 88], // Valores de eficiencia predicha para cada día de la semana
    },
  ];

  var options: any = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      margin: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      show: true,
      curve: "smooth", // Línea suave para reflejar la tendencia continua
      lineCap: "butt",
      width: 2,
      dashArray: 0,
    },
    dataLabels: {
      enabled: false,
    },
    colors: chartColors,
    xaxis: {
      categories: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ], // Categorías del eje X representando días de la semana
    },
    yaxis: {
      min: 0,
      max: 100, // Configurando un rango de 0 a 100% para la eficiencia operativa
      labels: {
        formatter: function (value: number) {
          return value + "%"; // Añadiendo un símbolo de porcentaje a las etiquetas del eje Y
        },
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        data-chart-colors={JSON.stringify(chartColors)}
        id="predictedEfficiency"
        className="apex-charts"
        type="line"
        height={350}
      />
    </React.Fragment>
  );
};

const InteractionChart = ({ chartId }: any) => {
    const chartColors = [
      "#6c757d", // Gris medio oscuro
      "#adb5bd", // Gris medio
      "#ced4da", // Gris medio claro
    ];
  
    // Definimos las series con métricas relevantes para un reporte AI-Driven Operational Insights, agrupadas por días de la semana
    const series = [
      {
        name: "Anomaly Detections",
        data: [5, 7, 6, 10, 12, 15], // Lunes a Domingo
      },
      {
        name: "Resource Optimization Rate",
        data: [8, 8, 3, 8, 9, 2], // Lunes a Domingo
      },
      {
        name: "Predictive Maintenance Actions",
        data: [2, 4, 3, 5, 6, 7], // Lunes a Domingo
      },
    ];
  
    var options: any = {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      xaxis: {
        categories: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      stroke: {
        show: true,
        width: 4,
        colors: ["transparent"],
      },
      grid: {
        show: false,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: -10,
        },
      },
      colors: chartColors,
    };
  
    return (
      <React.Fragment>
        <ReactApexChart
          dir="ltr"
          options={options}
          series={series}
          data-chart-colors={JSON.stringify(chartColors)}
          id="aiOperationalMetrics"
          className="apex-charts"
          type="bar"
          height={350}
        />
      </React.Fragment>
    );
  };
  

const UserDeviceChart = ({ chartId }: any) => {
  const chartColors = useChartColors(chartId);

  // Service Exceptions grouped by days
  const series = [
    {
      name: "Customer Not Receiving (CN)",
      data: [10, 15, 8, 12, 20, 5],
    },
    {
      name: "Address Not Found (AN)",
      data: [5, 10, 7, 13, 9, 14],
    },
    {
      name: "No Access (NA)",
      data: [8, 12, 6, 9, 15, 10],
    },
    {
      name: "Other Issues (OI)",
      data: [4, 6, 9, 11, 8, 5],
    },
  ];

  const options: any = {
    chart: {
      height: 240,
      type: "radar",
      dropShadow: {
        enabled: true,
        blur: 1,
        left: 1,
        top: 1,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 2,
    },
    fill: {
      opacity: 0.1,
    },
    markers: {
      size: 0,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
    colors: chartColors,
    legend: {
      position: "right",
      offsetY: 0,
      height: 230,
    },
    xaxis: {
      categories: [
        "Monday",
        "Tuesday",
        "Wednesd",
        "Thursd",
        "Friday",
        "Saturd",
      ],
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        data-chart-colors='["bg-custom-500", "bg-green-500", "bg-orange-500"]'
        id="serviceExceptionsChart"
        className="apex-charts"
        type="radar"
        height={240}
      />
    </React.Fragment>
  );
};

const SatisfactionChart = ({ chartId }: any) => {
  const chartColors = ["#343a40"]; // Gris fuerte

  //Satisfaction Rate
  const series = [92.33];
  var options: any = {
    chart: {
      type: "radialBar",
      height: 450,
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
        },
        hollow: {
          size: "60%",
          margin: 0,
          image: logoSm,
          imageWidth: 36,
          imageHeight: 36,
          imageClipped: false,
          imageOffsetY: -60,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -5,
            fontSize: "22px",
            fontWeight: "600",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    colors: chartColors,
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    stroke: {
      dashArray: 4,
    },
    labels: ["Average Results"],
  };
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        data-chart-colors='["bg-custom-500"]'
        id="satisfactionRate"
        className="apex-charts"
        type="radialBar"
        height={450}
      />
    </React.Fragment>
  );
};

const DailyVisitChart = ({ chartId }: any) => {
  const chartColors = useChartColors(chartId);

  // Ajuste de las series de datos para reflejar visitas con entrega completada y fallida, sumando 500 por día
  const series = [
    {
      name: "Completed Deliveries",
      data: [450, 320, 400, 460, 390, 470, 430], // Valores ajustados para sumarse a 500 con Failed Deliveries
    },
    {
      name: "Failed Deliveries",
      data: [50, 180, 100, 40, 110, 30, 70], // Los restantes para completar 500 por día
    },
  ];

  var options: any = {
    colors: chartColors,
    chart: {
      height: 238,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "70%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], // Días de la semana
      tickPlacement: "on",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100],
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: -10,
        right: -10,
        left: -10,
      },
    },
    yaxis: {
      show: true,
      labels: {
        formatter: function (value: number) {
          return value.toString(); // Etiquetas de valores en el eje Y
        },
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        data-chart-colors='["#34c38f", "#f46a6a"]' // Colores para entregas completadas y fallidas
        id="dailyVisitInsightsChart"
        className="apex-charts"
        type="bar"
        height={238}
      />
    </React.Fragment>
  );
};

const ReportChart = ({ chartId }: any) => {
  const chartColors = useChartColors(chartId);
  // Line with Data Labels
  const series = [
    {
      name: "Income - 2023",
      data: [28, 29, 33, 36, 32],
    },
    {
      name: "Expense - 2023",
      data: [20, 17, 21, 29, 23],
    },
  ];
  var options: any = {
    chart: {
      height: 235,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: chartColors,
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
      size: 2,
    },
    markers: {
      size: 1,
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      categories: ["Mar", "Apr", "May", "Jun", "Jul"],
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: -25,
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        data-chart-colors='["bg-custom-500", "bg-green-500"]'
        id="lineWithDataLabel"
        className="apex-charts"
        type="line"
        height={235}
      />
    </React.Fragment>
  );
};

const SubscriptionChart = ({ chartId }: any) => {
  const chartColors = useChartColors(chartId);
  //Subscription Distribution
  const series = [44, 55, 41, 17, 15];
  var options: any = {
    labels: ["Beginner", "Intermediate", "Enterprise", "VIP", "Professional"],
    chart: {
      height: 270,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        donut: {
          size: "75%",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    colors: chartColors,
    legend: {
      position: "bottom",
      formatter: function (val: any, opts: any) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },
    // legend: {

    // }
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        data-chart-colors='["bg-custom-500", "bg-orange-500", "bg-green-500", "bg-yellow-500", "bg-purple-500"]'
        id="subscriptionDistribution"
        className="apex-charts"
        type="donut"
        height={270}
      />
    </React.Fragment>
  );
};

export {
  PerspectiveChart,
  LocationChart,
  InteractionChart,
  UserDeviceChart,
  SatisfactionChart,
  DailyVisitChart,
  ReportChart,
  SubscriptionChart,
};
