import React, { useEffect, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";

import {
  Circle,
  FeatureGroup,
  LayerGroup,
  MapContainer,
  Marker,
  Polygon,
  Popup,
  TileLayer,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";

// Leaflet.Icon.Default.mergeOptions({
//     iconUrl: "http://localhost:3000/assets/libs/leaflet/images/marker-icon.png",
//     shadowUrl: "http://localhost:3000/assets/libs/leaflet/images/marker-shadow.png"
// })

// Icon
import {
  Search,
  ChevronDown,
  ChevronUp,
  Star,
  List,
  LayoutGrid,
  X,
  Heart,
  ShoppingCart,
  MoreHorizontal,
  Eye,
  FileEdit,
  Trash2,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";

import { Dropdown } from "Common/Components/Dropdown";
import { Link } from "react-router-dom";
import DeleteModal from "Common/DeleteModal";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import {
  getProductGrid as onGetProductGrid,
  deleteProductGrid as onDeleteProductGrid,
} from "slices/thunk";
import { ToastContainer } from "react-toastify";

const GridView = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.Ecommerce,
    (state) => ({
      dataList: state.productGrid,
    })
  );

  const { dataList } = useSelector(selectDataList);

  const [data, setData] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();
  const [list, setList] = useState<boolean>(true);

  // Get Data
  useEffect(() => {
    dispatch(onGetProductGrid());
  }, [dispatch]);

  useEffect(() => {
    setData(dataList);
  }, [dataList]);

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      dispatch(onDeleteProductGrid(eventData.id));
      setDeleteModal(false);
    }
  };

  const btnFav = (ele: any) => {
    if (ele.closest("a").classList.contains("active")) {
      ele.closest("a").classList.remove("active");
    } else {
      ele.closest("a").classList.add("active");
    }
  };
  return (
    <React.Fragment>
      <BreadCrumb title="Delivery Service Partner" pageTitle="Settings" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 2xl:grid-cols-12 gap-x-5 ">
        <div className="hidden 2xl:col-span-3 2xl:block">
          <div className="card">
            <div className="card-body">
              <div className="flex items-center gap-3">
                <h6 className="text-15 grow">Filter</h6>
                <div className="shrink-0">
                  <Link
                    to="#!"
                    className="underline transition-all duration-200 ease-linear hover:text-custom-500"
                  >
                    Clear All
                  </Link>
                </div>
              </div>

              <div className="relative mt-4">
                <input
                  type="text"
                  className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Search for ..."
                  autoComplete="off"
                />
                <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
              </div>

              <div className="mt-4 collapsible">
                <button className="flex items-center w-full text-left collapsible-header group">
                  <h6 className="underline grow">Provider</h6>
                  <div className="shrink-0 text-slate-500 dark:text-zink-200">
                    <ChevronDown className="hidden size-4 group-[.show]:inline-block" />
                    <ChevronUp className="inline-block size-4 group-[.show]:hidden" />
                  </div>
                </button>
                <div className="mt-4 collapsible-content">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <input
                        id="priceAll"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        checked={true}
                      />
                      <label
                        htmlFor="priceAll"
                        className="align-middle cursor-pointer"
                      >
                        All
                      </label>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        id="price1"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="price1"
                        className="align-middle cursor-pointer"
                      >
                        JRC SA DE CV
                      </label>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        id="price2"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="price2"
                        className="align-middle cursor-pointer"
                      >
                        DSP Solutions SA de CV
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 collapsible">
                <button className="flex items-center w-full text-left collapsible-header group">
                  <h6 className="underline grow">Capacity</h6>
                  <div className="shrink-0 text-slate-500 dark:text-zink-200">
                    <ChevronDown className="hidden size-4 group-[.show]:inline-block" />
                    <ChevronUp className="inline-block size-4 group-[.show]:hidden" />
                  </div>
                </button>
                <div className="mt-4 collapsible-content">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <input
                        id="priceAll"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        checked={true}
                      />
                      <label
                        htmlFor="priceAll"
                        className="align-middle cursor-pointer"
                      >
                        All
                      </label>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        id="price1"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="price1"
                        className="align-middle cursor-pointer"
                      >
                       500/Day
                      </label>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        id="price2"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="price2"
                        className="align-middle cursor-pointer"
                      >
                        +1000/Day
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 collapsible">
                <button className="flex items-center w-full text-left collapsible-header group">
                  <h6 className="underline grow">Performance</h6>
                  <div className="shrink-0 text-slate-500 dark:text-zink-200">
                    <ChevronDown className="hidden size-4 group-[.show]:inline-block" />
                    <ChevronUp className="inline-block size-4 group-[.show]:hidden" />
                  </div>
                </button>
                <div className="mt-4 collapsible-content">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <input
                        id="priceAll"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        checked={true}
                      />
                      <label
                        htmlFor="priceAll"
                        className="align-middle cursor-pointer"
                      >
                        All
                      </label>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        id="price1"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="price1"
                        className="align-middle cursor-pointer"
                      >
                       90%+
                      </label>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        id="price2"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="price2"
                        className="align-middle cursor-pointer"
                      >
                        80%+
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 collapsible">
                <button className="flex items-center w-full text-left collapsible-header group">
                  <h6 className="underline grow">Region/Area</h6>
                  <div className="shrink-0 text-slate-500 dark:text-zink-200">
                    <ChevronDown className="hidden size-4 group-[.show]:inline-block" />
                    <ChevronUp className="inline-block size-4 group-[.show]:hidden" />
                  </div>
                </button>
                <div className="mt-4 collapsible-content">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldiaAll"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        checked={true}
                      />
                      <label
                        htmlFor="alcaldiaAll"
                        className="align-middle cursor-pointer"
                      >
                        All
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia1"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia1"
                        className="align-middle cursor-pointer"
                      >
                        Álvaro Obregón
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia2"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia2"
                        className="align-middle cursor-pointer"
                      >
                        Azcapotzalco
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia3"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia3"
                        className="align-middle cursor-pointer"
                      >
                        Benito Juárez
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia4"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia4"
                        className="align-middle cursor-pointer"
                      >
                        Coyoacán
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia5"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia5"
                        className="align-middle cursor-pointer"
                      >
                        Cuajimalpa de Morelos
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia6"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia6"
                        className="align-middle cursor-pointer"
                      >
                        Cuauhtémoc
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia7"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia7"
                        className="align-middle cursor-pointer"
                      >
                        Gustavo A. Madero
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia8"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia8"
                        className="align-middle cursor-pointer"
                      >
                        Iztacalco
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia9"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia9"
                        className="align-middle cursor-pointer"
                      >
                        Iztapalapa
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia10"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia10"
                        className="align-middle cursor-pointer"
                      >
                        Magdalena Contreras
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia11"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia11"
                        className="align-middle cursor-pointer"
                      >
                        Miguel Hidalgo
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia12"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia12"
                        className="align-middle cursor-pointer"
                      >
                        Milpa Alta
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia13"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia13"
                        className="align-middle cursor-pointer"
                      >
                        Tláhuac
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia14"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia14"
                        className="align-middle cursor-pointer"
                      >
                        Tlalpan
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia15"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia15"
                        className="align-middle cursor-pointer"
                      >
                        Venustiano Carranza
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <input
                        id="alcaldia16"
                        className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                        type="checkbox"
                        value=""
                      />
                      <label
                        htmlFor="alcaldia16"
                        className="align-middle cursor-pointer"
                      >
                        Xochimilco
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              
             
            </div>
          </div>
        </div>
        <div className="2xl:col-span-9">
          <div className="flex flex-wrap items-center gap-2">
            <div className="flex gap-2 shrink-0 items-cente">
              {/* <Dropdown className="relative dropdown">
                <Dropdown.Trigger
                  href="#!"
                  className="bg-gray-500 text-white btn border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:bg-zink-700 dark:hover:bg-custom-500 dark:ring-custom-400/20 dark:focus:bg-custom-500 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                >
                  Sort by: <b className="font-medium">Highest Performance</b>
                  <ChevronDown className="inline-block size-4 ltr:ml-1 rtl:mr-1" />
                </Dropdown.Trigger>

                <Dropdown.Content
                  placement="right-end"
                  className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md dropdown-menu min-w-[10rem] dark:bg-zink-600"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <Link
                      className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                      to="#!"
                    >
                      Lowest Performance
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                      to="#!"
                    >
                      Highest Performance
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                      to="#!"
                    >
                      Hight to Low
                    </Link>
                  </li>
                  <li className="pt-2 mt-2 border-t dark:border-zink-300/50">
                    <Link
                      className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                      to="#!"
                    >
                      Low to High
                    </Link>
                  </li>
                </Dropdown.Content>
              </Dropdown>
             <button
                type="button"
                id="listView"
                className={`flex items-center justify-center size-[37.5px] p-0 text-sky-500 btn bg-sky-100 hover:text-white hover:bg-sky-600 focus:text-white focus:bg-sky-600 [&.active]:text-white [&.active]:bg-sky-600 dark:bg-sky-500/20 dark:text-sky-400 dark:hover:bg-sky-500 dark:hover:text-white dark:focus:bg-sky-500 dark:focus:text-white dark:[&.active]:bg-sky-500 dark:[&.active]:text-white dark:ring-sky-400/20 ${
                  !list && "active"
                }`}
                onClick={() => setList(false)}
              >
                <List className="size-4" />
              </button>
              <button
                type="button"
                id="gridView"
                className={`flex items-center justify-center size-[37.5px] p-0 text-sky-500 btn bg-sky-100 hover:text-white hover:bg-sky-600 focus:text-white focus:bg-sky-600 [&.active]:text-white [&.active]:bg-sky-600 dark:bg-sky-500/20 dark:text-sky-400 dark:hover:bg-sky-500 dark:hover:text-white dark:focus:bg-sky-500 dark:focus:text-white dark:[&.active]:bg-sky-500 dark:[&.active]:text-white dark:ring-sky-400/20 ${
                  list && "active"
                }`}
                onClick={() => setList(true)}
              >
                <LayoutGrid className="size-4" />
              </button>*/}
            </div>
          </div>

          {/* <div className="flex items-center gap-3 mt-3">
            <span className="px-2.5 py-0.5 text-sm font-medium rounded-full border bg-slate-100 border-slate-300 text-slate-500 inline-flex items-center dark:bg-zink-800 dark:border-zink-500 dark:text-zink-200">
              High to Low{" "}
              <Link
                to="#!"
                className="transition text-slate-500 dark:text-zink-200 hover:text-slate-600 dark:hover:text-zink-100"
              >
                <X className="size-3 ltr:ml-1 rtl:mr-1" />
              </Link>
            </span>
            <span className="px-2.5 py-0.5 text-sm font-medium rounded-full border bg-slate-100 border-slate-300 text-slate-500 inline-flex items-center dark:bg-zink-800 dark:border-zink-500 dark:text-zink-200">
              New{" "}
              <Link
                to="#!"
                className="transition text-slate-500 dark:text-zink-200 hover:text-slate-600 dark:hover:text-zink-100"
              >
                <X className="size-3 ltr:ml-1 rtl:mr-1" />
              </Link>
            </span>
            <Link
              to="#!"
              className="px-2.5 py-0.5 text-sm font-medium rounded border bg-transparent border-transparent text-slate-500 transition hover:bg-slate-200 dark:bg-zink-800 dark:hover:bg-zink-600 dark:text-zink-200"
            >
              All Clear
            </Link>
          </div>*/}

          <div className="mt-5">
            <iframe
              src="https://www.google.com/maps/d/u/2/embed?mid=1HCOQRHFfh0rjVyxZeY0rLllD6LWuajU&ehbc=2E312F&noprof=1"
              width="1280"
              height="900"
            ></iframe>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GridView;
