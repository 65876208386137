import React from "react";
import { TotalEmployeeChart } from "./Charts";
import CountUp from "react-countup";
import ApplicationReceived from "./ApplicationReceived";
import { Link } from "react-router-dom";

const Widgets = () => {
  return (
    <React.Fragment>
      <div className="col-span-12 md:order-3 lg:col-span-6 2xl:col-span-3 card">
        <Link to="/alerts/pod">
          <div className="card-body">
            <div className="grid grid-cols-12">
              <div className="col-span-8 md:col-span-9">
                <p className="text-orange-500 dark:text-slate-200">
                  False Delivery Evidence
                </p>
                <h5 className="mt-3 mb-4">
                  <CountUp end={120} className="counter-value" />
                </h5>
              </div>
              <div className="col-span-4 md:col-span-3">
                <TotalEmployeeChart
                  chartId="totalEmployee"
                  dataChartColor='["bg-custom-500"]'
                  series={[80]}
                />
              </div>
            </div>
            <div className="flex items-center gap-3 mt-3">
              <p className="text-slate-500 dark:text-slate-200 grow">
                <span className="font-medium text-green-500">15%</span> Increase
              </p>
              <p className="text-slate-500 dark:text-slate-200">Today</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-span-12 md:order-4 lg:col-span-6 2xl:col-span-3 card">
        <div className="card-body">
          <div className="grid grid-cols-12">
            <div className="col-span-8 md:col-span-9">
              <p className="text-orange-500 dark:text-slate-200">
                Mismatched GPS Coordinates
              </p>
              <h5 className="mt-3 mb-4">
                {" "}
                <CountUp end={80} className="counter-value" />
              </h5>
            </div>
            <div className="col-span-4 md:col-span-3">
              <TotalEmployeeChart
                chartId="totalApplication"
                dataChartColor='["bg-purple-500"]'
                series={[60]}
              />
            </div>
          </div>
          <div className="flex items-center gap-3 mt-3">
            <p className="text-slate-500 dark:text-slate-200 grow">
              <span className="font-medium text-green-500">26%</span> Increase
            </p>
            <p className="text-slate-500 dark:text-slate-200">Today</p>
          </div>
        </div>
      </div>

      <ApplicationReceived />

      <div className="col-span-12 md:order-5 2xl:order-6 lg:col-span-6 2xl:col-span-3 card">
        <div className="card-body">
          <div className="grid grid-cols-12">
            <div className="col-span-8 md:col-span-9">
              <p className="text-orange-500 dark:text-slate-200">
                Bulk Updates in Minimal Time
              </p>
              <h5 className="mt-3 mb-4">
                {" "}
                <CountUp end={5} className="counter-value" />
              </h5>
            </div>
            <div className="col-span-4 md:col-span-3">
              <TotalEmployeeChart
                chartId="hiredCandidates"
                dataChartColor='["bg-green-500"]'
                series={[8]}
              />
            </div>
          </div>
          <div className="flex items-center gap-3 mt-3">
            <p className="text-slate-500 dark:text-slate-200 grow">
              <span className="font-medium text-red-500">05%</span> Increase
            </p>
            <p className="text-slate-500 dark:text-slate-200">Today</p>
          </div>
        </div>
      </div>
      <div className="col-span-12 md:order-6 2xl:order-7 lg:col-span-6 2xl:col-span-3 card">
        <div className="card-body">
          <div className="grid grid-cols-12">
            <div className="col-span-8 md:col-span-9">
              <p className="text-orange-500 dark:text-slate-200">
                Suspicious Route Deviations
              </p>
              <h5 className="mt-3 mb-4">
                <CountUp end={3} className="counter-value" />
              </h5>
            </div>
            <div className="col-span-4 md:col-span-3">
              <TotalEmployeeChart
                chartId="rejectedCandidates"
                dataChartColor='["bg-red-500"]'
                series={[2]}
              />
            </div>
          </div>
          <div className="flex items-center gap-3 mt-3">
            <p className="text-slate-500 dark:text-slate-200 grow">
              <span className="font-medium text-red-500">16%</span> Increase
            </p>
            <p className="text-slate-500 dark:text-slate-200">Today</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Widgets;
